export default {
    menu:{
        home:"首页",
        product:"产品",
        category:"分类",
        consult:"咨询",
        contactus:"联系我们",
        aboutUs:"关于我们"
    },
    language:{
        en:'English',
        zh:'中文'
    },
    home:{
        tel:"电话",
        email:"邮箱",
        searchPlaceholder:"请根据产品型号关键字查询",
        briefIntroduction:"骏恒科技有限公司 —— 一家电子元器件的分销商",
        briefContent:`您可以通过搜索产品型号或关键字来查找您想要的产品,或者点击"产品"来详细了解产品信息,根据网站顶部联系方式来与我们取得联系和下单,期待您们的来电`,
        recommendProducts:"推荐产品",
        hotProducts:"热门产品",
        manufacturer:"制造商",
        inventory:"库存剩余",
        detail:"详细",
        subscribeTitle:"订阅以获取最新信息",
        byEmail:"(以邮件形式)",
        subscribe:"订阅",
        quantity:'数量'
    },
    category:{
        allCategory:"所有分类"
    },
    contact:{
        title:"关于骏恒科技",
        aboutJH1:"骏恒科技成立于2014年,我们的团队从事于电子元器件19年有余。",
        companyContent:`我们可以帮助您以相对低的成本解决电子元器件的采购问题，在运营方面我们始终保持着真诚务实的态度。我们将为您提供高质量的产品，以达到和助力客户能够成功开发和生产产品。`,
        companyHope:"我们忠心希望骏恒科技能够成为您的最好的合作伙伴！",
        contactUs:"联系我们",
        contactBefore:"能够为您提供服务是我们的荣幸！您可以通过以下方式联系到我们。"
    },
    consult:{
        consultTop:"提交您想要咨询的信息，我们将通过邮件的形式为您解答！",
        productInformation:"产品信息",
        add:"新增",
        labelID:"ID",
        labelProductNumber:"*型号",
        labelBrand:"品牌",
        labelCount:"数量",
        labelTargetPrice:"*理想价格($)",
        labelNote:"备注",
        labelOption:"选项",
        labelYourInfo:"您的信息",
        labelName:"姓名",
        labelGender:"性别",
        labelCompanyName:"公司名称",
        labelReceivingMode:"选择联系方式",
        email:"邮件",
        phone:"电话",
        QQ:"QQ",
        male:"先生",
        lady:"女士",
        checkPhone:"请输入正确的手机号！",
        emptyEmail:"邮箱不能为空",
        checkEmail:"请输入正确的邮箱！",
        checkLastName:"请输入您的姓名",
        mostInquiries:"最多只允许同时提交三个型号！",
        successMsg:'成功了! 我们将以邮件形式回复',
        uploading:'上传中...'
    },
    product:{
        emptyData:"暂无数据",
        return:"返回",
        thProductName:"产品名称",
        thManuFacture:"制造商",
        thDescription:"描述",
        thCount:"库存",
        thOption:"选项",
        detailTitle:"产品",
        detailSub:" / 详情",
        labelProductName:"型号 : ",
        labelManufacture:"制造商 : ",
        labelClass:"分类 : ",
        labelEncapsulation:"封装 : ",
        otherInfo:"其他信息",
        labelYear:"年份 : ",
        labelCount:"库存 : ",
        labelDescription:"描述 : ",
        correlation:"相关"
    },
    footer:{
        workHour:"工作时间：周一至周五 上午9:30至下午18:00",
        contactWay:"邮件：",
        companyIntroduction:"Started to be an electronic component distributor in 2006"
    },
    common:{
        more:"更多",
        detail:"详细",
        remove:"移除",
        subbmit:"提交",
        reset:"重置"
    }
}